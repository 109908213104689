<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="blog">
      <b-card class="blog-edit-wrapper">

        <validation-observer
            ref="refFormObserver"
        >
          <!-- form -->
          <b-form
              class="mt-2">
            <b-row>
              <b-col md="6">
                <b-form-group
                    class="mb-2"
                    label="Title"
                    label-for="blog-edit-title"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                    <b-form-input
                        id="blog-edit-title"
                        v-model="blog.title"
                        :state="errors.length > 0 ? false:null"
                    />
                    <!--                  <small class="text-danger mt-3">{{ errors[0] }}</small>-->
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    class="mb-2"
                    label="Category"
                    label-for="category"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                    <v-select
                        v-if="blogCategories !== null"
                        id="category"
                        v-model="blog.blogCategoryId"
                        :clearable="false"
                        :options="blogCategories"
                        :reduce="name => name.blogCategoryId"
                        :state="errors.length > 0 ? false:null"
                        label="name"
                    />
                    <small class="text-danger mt-3">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field:  status -->
              <!--              <b-col cols="6">-->
              <!--                <b-form-group-->
              <!--                    class="mb-2 mt-1"-->
              <!--                    label="State"-->
              <!--                    label-for="status"-->
              <!--                >-->
              <!--                  <validation-provider-->
              <!--                      #default="{ errors }"-->
              <!--                      rules="required"-->
              <!--                  >-->
              <!--                    <v-select-->
              <!--                        id="status"-->
              <!--                        v-model="blog.state"-->
              <!--                        :clearable="false"-->
              <!--                        :options="stateOptions"-->
              <!--                        :reduce="val => val.value"-->
              <!--                        :state="errors.length > 0 ? false:null"-->
              <!--                        dir="rtl"-->
              <!--                    />-->
              <!--                    <small class="text-danger mt-3">{{ errors[0] }}</small>-->
              <!--                  </validation-provider>-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->

              <!-- Field:  publishAt -->
              <!--              <b-col-->
              <!--                  cols="12"-->
              <!--                  md="6"-->
              <!--              >-->
              <!--                <b-form-group-->
              <!--                    label="تاریخ و زمان انتشار"-->
              <!--                    label-for=""-->
              <!--                >-->
              <!--                  <validation-provider-->
              <!--                      #default="{ errors }"-->
              <!--                      rules="required"-->
              <!--                  >-->
              <!--                    <input-->
              <!--                        :id="`start${1}`"-->
              <!--                        :state="errors.length > 0 ? false:null"-->
              <!--                        :value="createJalaliDate(blog.publishAt)"-->
              <!--                        class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"-->
              <!--                        placeholder="تاریخ و زمان انتشار"-->
              <!--                        type="text"-->
              <!--                    />-->
              <!--                  </validation-provider>-->
              <!--                  <custom-date-picker-->
              <!--                      v-model="blog.publishAt"-->
              <!--                      :element="`start${1}`"-->
              <!--                      :min="new Date(Date.now())"-->
              <!--                      auto-submit-->
              <!--                      class=""-->
              <!--                      color="#5c6bc0"-->
              <!--                      display-format="jYYYY/jMM/jDD HH:mm"-->
              <!--                      format="YYYY-MM-DD HH:mm"-->
              <!--                      input-format="YYYY-MM-DD HH:mm"-->
              <!--                      type="datetime"-->
              <!--                  ></custom-date-picker>-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->

              <!-- Field:  description -->
              <b-col
                  cols="12"
              >
                <b-form-group
                    label="Summary"
                    label-for="summary"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                    <b-form-textarea
                        id="summary"
                        v-model="blog.summary"
                        :state="errors.length > 0 ? false:null"
                        rows="2"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    class="mb-2"
                    label="Content"
                    label-for="blog-content"
                >
                  <ckEditorMain ref="editorContent" :defaultContent="blog.description"
                                @getEditorContent="setEditorContent"></ckEditorMain>
                </b-form-group>
              </b-col>

              <b-col
                  class="mb-2"
                  cols="12"
              >
                <div class="border rounded p-2">
                  <h4 class="mb-1">
                    Blog Picture
                  </h4>
                  <b-media
                      class="flex-column flex-md-row"
                      no-body
                      vertical-align="center"
                  >
                    <b-media-aside>
                      <b-img
                          ref="refPreviewEl"
                          :src="base64ImageSrc"
                          class="rounded mr-2 mb-1 mb-md-0"
                          height="110"
                          width="170"
                      />
                    </b-media-aside>
                    <b-media-body>
                      <div class="d-inline-block">
                        <b-form-file
                            ref="refInputEl"
                            accept=".jpg, .png, .gif"
                            placeholder="Choose picture"
                            @input="makeBase64Pic"
                        />
                      </div>
                    </b-media-body>
                  </b-media>
                </div>
              </b-col>
              <b-col
                  class="mt-50"
                  cols="12"
              >
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1"
                    type="submit"
                    variant="primary"
                    @click.prevent="validationForm"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <!--/ form -->
          <!--          <b-card class="blog-edit-wrapper">-->
          <!--            <h2>سئو</h2>-->

          <!--            <b-form class="mt-2">-->
          <!--              <b-row>-->
          <!--                <b-col class="" md="6">-->
          <!--                  <b-form-group-->
          <!--                      label="عنوان"-->
          <!--                  >-->
          <!--                    <validation-provider-->
          <!--                        #default="{ errors }"-->
          <!--                        rules="required"-->
          <!--                    >-->
          <!--                      <b-form-input-->
          <!--                          id="blog-edit-seoTitle"-->
          <!--                          v-model="blog.seoTitle"-->
          <!--                          :state="errors.length > 0 ? false:null"-->
          <!--                      />-->
          <!--                    </validation-provider>-->
          <!--                  </b-form-group>-->
          <!--                  <span :class="{'text-danger':blog.seoTitle.length>60}"-->
          <!--                        class="">تعداد کاراکترهای مجاز <small>{{ blog.seoTitle.length }}</small><small-->
          <!--                      class="text-secondary">/</small><small class="text-secondary">60</small></span>-->
          <!--                </b-col>-->
          <!--                <b-col md="6">-->
          <!--                  <b-row>-->
          <!--                    <b-col cols="10">-->
          <!--                      <b-form-group-->
          <!--                          class=""-->
          <!--                          label="آدرس"-->
          <!--                          label-for="blog-edit-seoUrl"-->
          <!--                      >-->
          <!--                        <validation-provider-->
          <!--                            #default="{ errors }"-->
          <!--                            rules="required"-->
          <!--                        >-->
          <!--                          <b-form-input-->
          <!--                              id="blog-edit-seoUrl"-->
          <!--                              ref="myInput"-->
          <!--                              v-model="allCharactersToDash"-->
          <!--                              :state="errors.length > 0 ? false:null"-->
          <!--                              v-on:focus="$event.target.select()"-->
          <!--                          />-->
          <!--                        </validation-provider>-->
          <!--                      </b-form-group>-->
          <!--                    </b-col>-->
          <!--                    <b-col cols="2">-->
          <!--                      <b-button-->
          <!--                          class="mt-2 ml-2"-->
          <!--                          variant="warning"-->
          <!--                          @click="copyText"-->
          <!--                      >-->
          <!--                        کپی-->
          <!--                      </b-button>-->
          <!--                    </b-col>-->
          <!--                  </b-row>-->
          <!--                </b-col>-->
          <!--                &lt;!&ndash; Field:  description &ndash;&gt;-->
          <!--                <b-col class="mt-2" cols="12">-->
          <!--                  <b-form-group-->
          <!--                      class="mb-2"-->
          <!--                      label="توضیحات"-->
          <!--                      label-for="blog-content"-->
          <!--                  >-->
          <!--                    <validation-provider-->
          <!--                        #default="{ errors }"-->
          <!--                        rules="required"-->
          <!--                    >-->
          <!--                      <b-form-textarea-->
          <!--                          v-model="blog.seoDescription"-->
          <!--                          :state="errors.length > 0 ? false:null"-->
          <!--                      ></b-form-textarea>-->
          <!--                    </validation-provider>-->
          <!--                  </b-form-group>-->
          <!--                  <span :class="{'text-danger':blog.seoDescription.length>160}"-->
          <!--                        class="">تعداد کاراکترهای مجاز <small>{{-->
          <!--                      blog.seoDescription.length-->
          <!--                    }}</small><small-->
          <!--                      class="text-secondary">/</small><small class="text-secondary">160</small></span>-->
          <!--                </b-col>-->

          <!--                <b-card class="mt-1 w-100">-->

          <!--                  <h2>نحوه نمایش در گوگل</h2>-->

          <!--                  <div class="col-md-12 my-3">-->
          <!--                    &lt;!&ndash;- serp Desktop -&ndash;&gt;-->
          <!--                    <div class="serp-preview d-lg-flex p-3 d-none flex-column align-items-end">-->
          <!--                      <div class="serp-title">-->
          <!--                        <h4 style="color: rgb(26, 13, 171)!important">-->
          <!--                          {{ blog.seoTitle }}-->
          <!--                        </h4>-->
          <!--                      </div>-->
          <!--                      <div class="serp-url">-->
          <!--                        <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">-->
          <!--                          <span style="overflow-wrap: anywhere">{{ blog.seoUrl.replaceAll(' ', '-') }}/</span>-->
          <!--                          <span>-->
          <!--                             {{ baseUrl }}-->
          <!--                        <span class="serp-arrow"></span>-->
          <!--                      </span>-->

          <!--                        </h5>-->
          <!--                      </div>-->
          <!--                      <div class="serp-description">-->
          <!--                        <h6 style="overflow-wrap: anywhere">-->
          <!--                          {{ blog.seoDescription }}-->
          <!--                        </h6>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    &lt;!&ndash;- serp Mobile -&ndash;&gt;-->
          <!--                    <div class="serp-preview d-lg-none  p-3 d-flex flex-column align-items-end">-->
          <!--                      <div class="serp-title">-->
          <!--                        <h6>-->
          <!--                          {{ blog.seoTitle }}-->
          <!--                        </h6>-->
          <!--                      </div>-->
          <!--                      <div class="serp-url d-flex align-items-center flex-wrap">-->

          <!--                        <small>{{ blog.seoUrl.replaceAll(' ', '-') }}/</small>-->
          <!--                        <small>-->
          <!--                          {{ baseUrl }}-->
          <!--                          <small class="serp-arrow"></small>-->
          <!--                        </small>-->
          <!--                      </div>-->
          <!--                      <div class="serp-description">-->
          <!--                        <small>-->
          <!--                          {{ blog.seoDescription }}-->
          <!--                        </small>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </b-card>-->

          <!--                <b-col-->
          <!--                    class="mt-2"-->
          <!--                    cols="12"-->
          <!--                >-->
          <!--                  <b-button-->
          <!--                      class="mr-1"-->
          <!--                      variant="primary"-->
          <!--                      @click="createBlog"-->
          <!--                  >-->
          <!--                    ثبت بلاگ-->
          <!--                  </b-button>-->
          <!--                </b-col>-->
          <!--              </b-row>-->
          <!--            </b-form>-->
          <!--          </b-card>-->
        </validation-observer>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,BFormTextarea,BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import {BlogGetByIdRequest} from "@/libs/Api/Blog";
import {BlogCategoryGetAllRequest} from "@/libs/Api/Blog";
import {BlogUpdateBlogRequest} from "@/libs/Api/Blog";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ckEditorMain from "@/views/components/ckEditorMain.vue";
import {required} from '@validations'

export default {
  title:"Blog Edeit",
  components: {
    ckEditorMain, ValidationProvider,ValidationObserver,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BOverlay,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      blog:null,
      blogId:this.$route.params.id,
      blogCategories:null,
      base64ImageSrc:null,
      required,
      blogEdit: {},
      blogFile: null,
      snowOption: {
        theme: 'snow',
      },
      stateOptions: [
        {label: 'draft', value: 0},
        {label: 'published', value: 1},
      ],
      overlay:false,
    }
  },
  async created() {
    await this.getBlog();
    await this.getBlogCategories();
    this.$http.get('/blog/list/data/edit').then(res => { this.blogEdit = res.data })
  },
  methods:{
    validationForm() {
      let _this = this;

      _this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          _this.updateBlog();
        }
      })
    },
    async getBlog(){
      let _this = this;
      _this.overlay = true;

      let blogGetByIdRequest = new BlogGetByIdRequest(_this);
      blogGetByIdRequest.setId(_this.blogId)
      await blogGetByIdRequest.fetch(function (content){
        _this.overlay = false;
        _this.blog = content;
        _this.base64ImageSrc = 'https://api.1minex.com/'+_this.blog.fileData;
        _this.blog.fileData= '';
      } , function (){
        _this.overlay = false;
        console.log('Error')
      });
    } ,
    async getBlogCategories(){
      let _this = this;
      _this.overlay = true;

      let blogCategoryGetAllRequest = new BlogCategoryGetAllRequest(_this);
      await blogCategoryGetAllRequest.fetch(function (content){
        _this.overlay = false;
        _this.blogCategories = content
      } , function (content){
        _this.overlay = false;
        console.log(content)
      });
    },
    async updateBlog(){
      let _this = this;
      _this.overlay = true;

      let blogUpdateBlogRequest = new BlogUpdateBlogRequest(_this);
      blogUpdateBlogRequest.setParams(_this.blog);
      await blogUpdateBlogRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Done`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `Blog edited.`,
          },
        })
        _this.getBlog();
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(){
      const _this = this;
      let file = _this.$refs.refInputEl.files[0]
      _this.base64ImageSrc = URL.createObjectURL(file)
      const result = await _this.readAsDataURL(file);
      _this.blog.fileData = result;
    },
    setEditorContent(content){
      this.blog.description = content
    },
    createJalaliDate(param) {
      if (param !== '' && param !== null) {
        return param.slice(11, 16) + ' - ' + new Date(param.slice(0, 10)).toLocaleDateString("fa-IR",);
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
